import React, { useState, useEffect, useRef } from "react";
import {
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { api_call, api_call_token } from "../../Utils/Network";
import Cards from "../../Components/Cards/Cards";
import live from "../../Assets/LIVE.png";
import inperson from "../../Assets/coaching.png";
import courses from "../../Assets/mid.png";
import moment from "moment";
import VideoPlayer from "../../Components/VideoPlayer";
import card1 from "../../Assets/cardBackground/card1.png";
import card2 from "../../Assets/cardBackground/card2.png";
import card3 from "../../Assets/cardBackground/card3.png";
import Sessionbook from "../../Assets/cardBackground/sessionbook.png";
import NoCouncilImg from "../../Assets/MyProfile/NoSession.png";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import "./index.css";
import styles from "./index.module.css";
import assingIcon from "../../Assets/assingmentIcon.png";
import assingActive from "../../Assets/assingmentIconActive.png";
import ppt from "../../Assets/pptIcon.png";
import pptActive from "../../Assets/pptIcon Active.png";
import ebook from "../../Assets/eBookIcon.png";
import ebookActive from "../../Assets/eBookIconactive.png";
import videoicon from "../../Assets/videoIcon.png";
import videoiconActive from "../../Assets/videoIconActive.png";
import mcq from "../../Assets/mcqIcon.png";
import mcqActive from "../../Assets/mcqIconActive.png";
import performanceIcon from "../../Assets/performanceIcon.png";
import performanceIconActive from "../../Assets/performanceIcon.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import PPT from "../../Components/CoursePage/PptPage/PptPage";
import Ebook from "../../Components/CoursePage/EbookPage/EbookPage";
import VideosPage from "../../Components/CoursePage/VideosPage/VideosPage";
import MCQ from "../../Components/CoursePage/McqPage/McqPage";
import Assignment from "../../Components/CoursePage/AssingmentPage/AssingmentPage";
import DoitYourself from "../../Components/CoursePage/AssingmentPage/DoitYourself";
import Performance from "../../Components/CoursePage/PerformancePage/PerformancePage";
import LearnZoneTest from "../LearnZoneTest/LearnZoneTest";
import { useHistory, useParams, useLocation } from "react-router-dom";
import noData from "../../Assets/nodata.png";
// import pptshow from "../../../Assets/pptPageimg.png";
import pptshow from "../../Assets/course_img.png";
import backArrowBtn from "../../Assets/backArrow.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
    marginTop: "85px",
    flexGrow: 1,
    backgroundColor: "",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  muiFles: {
    flexDirection: "column",
  },
}));

const Course = [
  {
    data: [],
    icon: null,
    title: "Live",
    type: 1,
  },
  {
    data: [],
    icon: null,
    title: "In-Person",
    type: 2,
  },
  {
    data: [],
    icon: null,
    title: "Courses",
    type: 3,
  },
];

export default function MySpacePage() {
  const [loading, setLoading] = useState(true);
  const [showpanel, setShowPanel] = useState(true);
  const [drawer, setDrawer] = useState("");
  const [bookedSlot, setBookedSlot] = useState([]);
  const [bookedSlotError, setBookedSlotError] = useState([]);
  const [currentCourses, setCurrentCourses] = useState([]);
  const [allCourses, setAllCourses] = useState(Course);
  const [council, setCouncil] = useState(0);
  const [selectedCourses, setSelectedCourses] = useState(Course);
  const [playbackVideos, setPlaybackVideos] = useState([]);
  const [courseList, setCourselist] = useState([]);
  const playerRef = React.useRef(null);
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const cardsBackground = [card1, card2, card3];
  const [selectCourse, setSelectCourse] = useState("");
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { tabId, ppt, courseId } = params;
  const [selectedTab, setSelectedTab] = useState(ppt);
  const [activeIndex, setActiveIndex] = useState(ppt);
  const [showTest, setShowTest] = useState(false);
  const tabListRef = useRef(null);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  const handelSelectCourse = (couresName) => {
    history.push(
      `/my-space/1/${couresName?.id}/e-book?name=${couresName?.title}`
    );
    // console.log(ppt, "couresName");
    setSelectedTab("e-book");
    setActiveIndex("e-book");
    // setSelectCourse(couresName)
  };
  console.log(selectCourse?.title, "couresName");
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });
    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  // console.log(ppt, "userparams");
  useEffect(() => {
    getCourse();
    getStudentTimetable();
    getPlaybackVideos();
    getPurchasedSlot();
  }, []);

  // useEffect(() => {
  //   joinSession();
  // }, []);

  useEffect(() => {
    // Get the selected tab index from local storage
    const storedTabIndex = localStorage.getItem("selectedTabIndex");
    if (storedTabIndex !== null) {
      setValue(parseInt(storedTabIndex));
    }
    // Set the initial tab based on the URL parameter
    const urlTabIndex = parseInt(
      new URLSearchParams(location.search).get("tab"),
      10
    );
    if (!isNaN(urlTabIndex)) {
      setValue(urlTabIndex);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    // Update local storage with the selected tab index
    localStorage.setItem("selectedTabIndex", newValue);

    console.log(newValue, "selectedTabIndex");
    if (newValue == 0) {
      history.push(`/my-space/${newValue}`);
    } else if (newValue == 1) {
      // history.push(`/my-space/${newValue}/e-book`);
      history.push(`/my-space/${newValue}`);
    }
    // Update the URL without reloading the page

    setValue(newValue);

    // when user switch the tab it will naviget to first
    setSelectedTab("e-book");
    setActiveIndex("e-book");
    // localStorage.setItem("setValue", newValue);
  };

  const handleClickchange = (dynamic, id) => {
    history.push(`/my-space/1/${courseId}/${dynamic}`);
    console.log(dynamic, "dynamic");
    setSelectedTab(dynamic);
    setActiveIndex(dynamic);
  };

  useEffect(() => {
    // // Save the selected tab index to local storage
    // localStorage.setItem("selectedTabIndex", value);
    // // Cleanup function to clear local storage data when leaving the page
    // return () => {
    //   localStorage.removeItem("selectedTabIndex");
    // };
    // Scroll to the active tab when the component mounts or when activeIndex changes
    if (tabListRef.current && activeIndex !== null) {
      const activeTab = tabListRef.current.querySelector(
        `[data-index="${activeIndex}"]`
      );
      if (activeTab) {
        activeTab.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    }
  }, [activeIndex]); // change dependency to value if need the commented code and make seprate useEffect

  const handleTakeTestClick = (v) => {
    const extraData = {
      marks: v?.marks,
      total_question: v?.total_question,
      time: v?.time,
      title: v?.title,
    };

    console.log(v, "Values");
    history.push(
      `/my-space/1/${courseId}/${ppt}/${v?.id}?marks=${v?.marks}&total_question=${v?.marks}&time=${v?.time}&title=${v?.title}`
    );
    setShowTest(!showTest);

    console.log(showTest, "4727642746246239");
    // Update the state to show LearnZoneTest
  };

  // useEffect(() => {
  //   // localStorage.setItem('activeTab', value);
  //   history.push("/my-space");
  // }, []);

  const getPurchasedSlot = () => {
    api_call_token
      .get("/counseling/purchase/view_orders/")
      .then((res) => {
        if (res.status === 200 && res.data.data.length) {
          console.log(res.data.data, " Purchased Slot");
          setBookedSlot(res.data.data);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred in Purchased Slot");
      });
  };
  const joinSession = (id) => {
    api_call_token
      .get(`counseling/session/${id}/join/`)
      .then((res) => {
        console.log(res);
        if (res.data.data.url) {
          localStorage.setItem("slotsID", id);
          console.log(res.data.data.url, " res.data.data.url");
          setBookedSlot(res.data.data);
          window.open(res.data.data.url, "_blank");
        } else {
          console.log("is_start");
          setBookedSlotError(res);
          console.log(bookedSlotError, " is_start");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred in join Session Slot");
      });
  };
  const getCourse = () => {
    api_call_token
      .get("/purchase/purchased_course_filter")
      .then((res) => {
        if (res.status === 200 && res.data.data.length) {
          setAllCourses(res.data.data);
          setSelectedCourses(res.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred");
      });
  };
  const getStudentTimetable = () => {
    api_call_token
      .post("/content/student_timetable", {
        dates: moment().format("yyyy-MM-DD"),
      })
      .then((res) => {
        if (res.status === 200 && res.data.data.length) {
          // console.log(res.data.data, "my space");
          setCurrentCourses(res.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred");
      });
  };
  const getPlaybackVideos = () => {
    api_call_token
      .get("/content/resume_video")
      .then((res) => {
        if (res.status === 200 && res.data.data.length) {
          console.log("video resume watching", res.data);
          setPlaybackVideos(res.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred");
      });
  };

  // console.log(bookedSlot, "bookedSlot");

  // ////////////////////////////////////////////////////////////////////
  //   coures Listing api
  // ////////////////////////////////////////////////////////////////////
  const getCourseListing = () => {
    api_call_token
      .get(`content/V1/lms/course`)
      .then((res) => {
        if (res?.status == 200 && res?.data?.data.length) {
          setCourselist(res?.data?.data);
          console.log(res?.data?.data, "getCourseListing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCourseListing();
  }, []);

  console.log(courseList, "lengthofcour");

  const handleBackClick = () => {
    history.push("/my-space/1/");
    // onTakeTest(v);
  };
  // ////////////////////////////////////////////////////////////////////
  //   coures Listing api end
  // ////////////////////////////////////////////////////////////////////

  return (
    <>
      {/* <div className={classes.root}> */}
      <AppBar position="static" style={{ backgroundColor: "#13868b" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Alphaguide Counselling"
            {...a11yProps(0)}
            // component={Link}
            // to="/my-space?tab=0"
          />
          <Tab
            label="STEAM Courses"
            {...a11yProps(1)}
            // component={Link}
            // to="/my-space?tab=1"
          />
        </Tabs>
      </AppBar>
      {/* {
          showTest ? (
          <LearnZoneTest info={v} onTakeTest={handleTakeTestClick}/>
          ) :(<> */}
      <TabPanel value={value} index={0}>
        {/* <div
                className="drr-l-lab"
                style={{ textAlign: "center", padding: "20px" }}
                onClick={() => setCouncil(0)}
              >
                <span>Career Counselor</span>
              </div> */}
        {council === 0 && (
          <div className="card_container">
            <div className="Cards_Myspace">
              {/*   ===================================  Available Slot ===================================*/}

              {bookedSlot.length > 0 && (
                <>
                  <Box
                    textAlign="left"
                    m={1}
                    variant="h5"
                    className="main-session-title drr-l-lab"
                  >
                    Available session
                  </Box>
                  {bookedSlot.length > 0 &&
                    bookedSlot.map((slots) => (
                      <>
                        {/* <Box
                          textAlign="left"
                          m={1}
                          variant="h5"
                          className="main-session-title drr-l-lab"
                        >
                          Available session
                        </Box> */}
                        {console.log(slots.slot.is_instructor_alloted, "slots")}
                        {slots.is_expired === false && (
                          <div
                            className={` cards-box card-session-book`}
                            style={{
                              backgroundBlendMode: `${
                                slots.is_expired ? "luminosity" : ""
                              }`,
                            }}
                          >
                            <Card
                              className={
                                slots.slot.is_available == false
                                  ? ""
                                  : "available_container"
                                // ? "expired_container"
                              }
                              elevation={0}
                              style={{
                                width: "100%",
                                height: "auto",
                                background: "none",
                                textAlign: "left",
                              }}
                            >
                              <div className="bx-content">
                                <p className="bx-expired_header">
                                  {slots.slot.session_name}
                                </p>

                                {/* <div style={{ textAlign: "left" }}>
                                        <p className="bx-amount">
                                          Amount: <span>&#8377;</span>
                                          {slots?.amount_paid}
                                        </p>
                                      </div> */}
                                <div className="content-div">
                                  <p
                                    className={
                                      "bx-date_available"
                                      //   slots.slot.is_expired
                                      //     ? "bx-date"
                                      //     : "bx-date_available"
                                    }
                                  >
                                    {moment(
                                      `${slots.slot.date}`,
                                      "DD-MM-YYYY"
                                    ).format("dddd, DD-MMMM-YYYY")}
                                    <br />
                                    {`${moment(
                                      slots.slot.start_time,
                                      "HH:mm:ss "
                                    ).format("hh:mm a")}
                                           to
                                          ${moment(
                                            slots.slot.end_time,
                                            "HH:mm:ss"
                                          ).format("hh:mm a")}`}
                                  </p>

                                  <div>
                                    <p
                                      // disabled={
                                      //   slots.slot.is_available === false
                                      //     ? true
                                      //     : false
                                      // }
                                      // disabled={slots.slot.is_available}
                                      // disabled={
                                      //   slots.slot.is_available ? false : true
                                      // }
                                      onClick={() => joinSession(slots.slot.id)}
                                      // className={`${
                                      //   slots.slot.is_available == true
                                      //     ? " drop_profile join_btn"
                                      //     : "bx-expired"
                                      // }`}
                                      className={`${
                                        slots.slot.is_available == true
                                          ? " drop_profile join_btn"
                                          : "btn_ng_gn btn_nv_tech "
                                      }`}
                                      // size="small"
                                    >
                                      Join Now
                                      {/*  {slots.slot.is_available == true
                                        ? "Go Live Now"
                                        : "Session Expired"} */}
                                    </p>
                                  </div>
                                </div>
                                <p className="bx-new-card drop_profil">
                                  {slots.slot.is_instructor_alloted
                                    ? `Counselor name: ${slots.slot.instructor.first_name} ${slots.slot.instructor.last_name}`
                                    : "counselor to be assigned"}
                                </p>
                              </div>
                            </Card>
                          </div>
                        )}
                      </>
                    ))}
                </>
              )}
              {/*   ===================================  Expired Slot ===================================*/}
              {bookedSlot.length > 0 && (
                <>
                  <Box
                    textAlign="left"
                    m={1}
                    variant="h5"
                    className="main-session-title drr-l-lab"
                  >
                    Expired Session
                  </Box>
                  {bookedSlot.length > 0 &&
                    bookedSlot.map((slots) => (
                      <>
                        {/* <Box
                              textAlign="left"
                              m={1}
                              variant="h5"
                              className="main-session-title drr-l-lab"
                            >
                              Expired Session
                            </Box> */}
                        {/* {console.log(slots, "slots")} */}
                        {slots.is_expired === true && (
                          <div
                            className={` cards-box card-session-book`}
                            style={{
                              backgroundBlendMode: `${
                                slots.is_expired ? "luminosity" : ""
                              }`,
                            }}
                          >
                            <Card
                              className={
                                slots.slot.is_available == false
                                  ? "expired_container"
                                  : "available_container"
                              }
                              elevation={0}
                              style={{
                                width: "100%",
                                height: "auto",
                                background: "none",
                                textAlign: "left",
                              }}
                            >
                              <div className="bx-content">
                                <p className="bx-expired_header">
                                  {slots.slot.session_name}
                                </p>

                                {/*<div style={{ textAlign: "left" }}>
    
    
                                         <p className="bx-amount">
                                          Amount: <span>&#8377;</span>
                                          {slots?.amount_paid}
                                        </p>
                                      </div>*/}
                                <div className="content-div">
                                  <p
                                    className={
                                      "bx-date_available"
                                      //   slots.slot.is_expired
                                      //     ? "bx-date"
                                      //     : "bx-date_available"
                                    }
                                  >
                                    {moment(
                                      `${slots.slot.date}`,
                                      "DD-MM-YYYY"
                                    ).format("dddd, DD-MMMM-YYYY")}
                                    <br />
                                    {`${moment(
                                      slots.slot.start_time,
                                      "HH:mm:ss "
                                    ).format("hh:mm a")}
                                           to
                                          ${moment(
                                            slots.slot.end_time,
                                            "HH:mm:ss"
                                          ).format("hh:mm a")}`}
                                  </p>
                                  {/* <div>
                                          <Button
                                            disabled={
                                              slots.slot.is_available ? false : true
                                            }
                                            onClick={() =>
                                              joinSession(slots.slot.id)
                                            }
                                            className={`  ${
                                              slots.slot.is_available == true
                                                ? " drop_profile join_btn"
                                                : "bx-expired"
                                            }`}
                                            size="small"
                                          >
                                            {slots.slot.is_available == true
                                              ? "Go Live Now"
                                              : "Session Expired"}
                                          </Button>
                                        </div> */}
                                </div>
                                <p className="bx-new-card">
                                  {slots.slot.is_instructor_alloted
                                    ? `Counselor name: ${slots.slot.instructor.first_name} ${slots.slot.instructor.last_name}`
                                    : "counselor to be assigned"}
                                </p>
                              </div>
                            </Card>
                          </div>
                        )}
                      </>
                    ))}
                </>
              )}
              {/*   =================================== No Slot Found ==================================*/}

              {bookedSlot.length == 0 && (
                <img
                  src={NoCouncilImg}
                  alt="No Slot Found"
                  className="NoCouncilImg"
                />
              )}
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!courseId && (
          <div style={{ minHeight: "65vh" }}>
            <h2
              className={styles.courseListingHeading}
              style={{ textAlign: "center" }}
            >
              Your Courses
            </h2>
            <div className={styles.tabListingContainer}>
              {courseList?.length == 0 && (
                <div className={styles.nodataAvl}>
                  <img src={noData} alt="nodata" />
                  <p style={{ fontSize: "20px", textAlign: "center" }}>
                    Courses are Coming Soon !{" "}
                  </p>
                </div>
              )}
              {courseList?.length > 0 && (
                <>
                  {courseList?.map((v, index) => {
                    return (
                      <div
                        className={styles.courseName}
                        key={v?.id}
                        onClick={() => handelSelectCourse(v)}
                      >
                        {/* <img
                          src={
                            v?.image 
                              ? v?.image
                              : pptshow
                          }
                          alt="Course"
                        /> */}
                        <div
                          style={{
                            background: `url(${
                              v?.image ? v?.image : pptshow
                            }) `,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "100%",
                            height: "250px",
                          }}
                        ></div>
                        <h4>{v?.title}</h4>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}

        {courseId && (
          <div style={{ minHeight: "65vh" }}>
            <div className={styles.backBtnwithHeading}>
              <div
                onClick={handleBackClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {/* <ArrowBackIcon /> */}
                <img src={backArrowBtn} alt="backarrow" />
                {/* <p>Back</p> */}
              </div>
              <p className={styles.couresHeading}>{selectCourse}</p>
            </div>

            <div className={styles.mainContainer}>
              <div className={styles.sideBar}>
                <ul className={styles.navigation}>
                  {sidebarlist?.map((info, index) => {
                    return (
                      <>
                        <li key={info?.id}>
                          <Link
                            className={`${styles.list} ${
                              info?.url == activeIndex ? styles.active : ""
                            }`}
                            onClick={() => {
                              handleItemClick(info?.url);
                              handleClickchange(info?.url, info?.id);
                            }}
                          >
                            {info?.url == activeIndex ? (
                              <img src={info.imgActive} />
                            ) : (
                              <img src={info.imginactive} />
                            )}

                            {info.title}
                          </Link>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className={styles.leftSideContainer}>
                {sidebarlist.map((item, index) => {
                  var Comp = item.component;
                  if (selectedTab != item?.url) return;
                  return (
                    <>
                      <Comp
                        onTakeTest={handleTakeTestClick}
                        setSelectCourse={setSelectCourse}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {/* <div className={styles.mainContainer}>
              <div className={styles.sideBar}>
                <ul className={styles.navigation}>
                  {sidebarlist?.map((info, index) => {
                    return (
                      <>
                        <li key={info?.id}>
                          <Link
                            className={`${styles.list} ${
                              info?.url == activeIndex ? styles.active : ""
                            }`}
                            onClick={() => {
                              handleItemClick(info?.url);
                              handleClickchange(info?.url, info?.id);
                            }}
                          >
                            {info?.url == activeIndex ? (
                              <img src={info.imgActive} />
                            ) : (
                              <img src={info.imginactive} />
                            )}
    
                            {info.title}
                          </Link>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className={styles.leftSideContainer}>
                
                {sidebarlist.map((item, index) => {
                  var Comp = item.component;
                  if (selectedTab != item?.url) return;
                  return (
                    <>
                      <Comp 
                      onTakeTest={handleTakeTestClick}
                      />
                    </>
                  );
                })}
                
              </div>
            </div> */}
      </TabPanel>
      {/* </>
          )
        } */}
      {/* </div> */}
    </>
  );
}

const sidebarlist = [
  // {
  //   id: 0,
  //   imgActive: pptActive,
  //   imginactive: ppt,
  //   title: "PPT",
  //   url: "ppt",
  //   component: PPT,
  // },
  {
    id: 1,
    imgActive: ebookActive,
    imginactive: ebook,
    title: "E-books",
    url: "e-book",
    component: Ebook,
  },
  // {
  //   id: 2,
  //   imgActive: videoiconActive,
  //   imginactive: videoicon,
  //   title: "Activity Videos",
  //   url: "videos",
  //   component: VideosPage,
  // },
  {
    id: 3,
    imgActive: mcqActive,
    imginactive: mcq,
    title: "MCQs",
    url: "mcq",
    component: MCQ,
  },
  {
    id: 4,
    imgActive: assingActive,
    imginactive: assingIcon,
    title: "Worksheets",
    url: "worksheets",
    component: Assignment,
  },
  {
    id: 5,
    imgActive: assingActive,
    imginactive: assingIcon,
    title: "Do it Yourself",
    url: "do-it-your-self",
    component: DoitYourself,
  },
  // {
  //   id: 5,
  //   imgActive: performanceIcon,
  //   imginactive: performanceIconActive,
  //   title: "Performance",
  //   url: "performance",
  //   component: Performance,
  // },
];
